@media screen /*and (min-width : 1024px) */
{
    .splitter {
        position: absolute;
        resize: horizontal;
        width: 8px;
        height: 80px;
        background-color: var(--handler-color);
    }

    .splitter:hover {
        background-color: var(--handler-color-hover);
    }

    .spaceFiller {
        height: 100vh;
        display: grid;
    }

    .saySomething {
        margin: auto;
    }
}
