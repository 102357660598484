@media screen /*and (min-width : 1024px) */
{
    .projects-tree {
        position: absolute;
        left: 0px;
        width: 307px;
        top: 40px;
        bottom: 32px;
        height: calc(100% - 64px);
        background-color: var(--background-color);
        border: none;
        border-right: 1px var(--border-color) solid;
        border-top: 1px var(--border-color) solid;

        /**
        position:relative;
        width: 100%;
        height: calc(100% - 10px);
        border-right: 1px solid var(--border-color);
        */
    }
    
    .projects-tree .splitter
    {
        top: calc(50% - 40px);
        right: -9px;
        cursor: ew-resize;
    }

    .treeview {
        position: absolute;
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        right: 0;
        left: 0;
        top: 0px;
        bottom: 0px;
    }

    .treeview .treeview-header {
        width: 100%;
        border-bottom: 2px solid var(--splitter-gutter-color);
    }

    .treeview-content-scroller
    {
        top: 38px;
        height: calc(100% - 71px);
    }

    /* https://syntackle.com/blog/changes-to-scrollbar-styling-in-chrome-121/ */
    @supports not selector(::-webkit-scrollbar)  {
        .treeview-content-scroller
        { /* FF & others */
            scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);;
            scrollbar-width: thin;
        }
    }

    @supports selector(::-webkit-scrollbar) {
        .treeview-content-scroller::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
    }


    .treeview-content
    {
        border: none;
    }

    .treeview ul {
        list-style: none;
        margin: 0px;
        padding-left: 0px;
    }

    .treeview li {
        margin: 0;
        padding: 0;
        list-style: none;
        line-height: 28px;
        padding-left: 16px;
    }

    .treeview i {
        margin-right: 5px;
    }

    .treeview li span {
        cursor: pointer;
    }

    .treeview li input[type="text"] {
        border: 3px var(--border-color) solid;
        border-radius: 4px;
        background-color: var(--input-background-color);
        color: var(--input-font-color);
        font-size: 14px;
        padding: 2px;
    }

    .treeview li input[type="text"]:focus {
        outline-offset: none;
        outline: none;
        border: 3px #0088aa solid;
        box-shadow: 0 0 10px #0088aa; 
    }

    .treeview li span.selected {
        background-color: var(--treeview-selected-background-color);
        color: var(--treeview-selected-color);
        height: 40px;
        border-radius: 4px;
        padding: 4px;
    }

    .tree-filters {
        margin: 10px;
        margin-right: 18px;
        height: 40px;
    }

    input[type="text"].tree-filters-input {
        margin: 0;
        background-color: var(--input-background-color);
        color: var(--input-font-color);
        height: 40px;
        font-size: var(--font-size);
        font-family:var(--font-name);
        padding-left: 8px;
        padding-right: 0px;
    }

    input[type="text"].tree-filters-input:focus {
        outline-offset: none;
        outline: none;
        border: 3px #0088aa solid;
        box-shadow: 0 0 10px #0088aa; 
    }

    input.tree-filters-input::placeholder {
        color: #888888;
        font-style: italic;
    }

    .tree-filters-icon {
        margin-left: -32px;
        color: #888888;
        cursor: pointer;
    }

    .tree-toolbar
    {
        display:flex;
        width: 100%;
        height: 28px;
        margin: 4px;
        overflow: hidden;
    }

    .tree-tool-separator {
        margin-right: 4px;
        margin-left: 4px;
        border-right: 2px solid var(--splitter-gutter-color);
    } 


    .tree-toolbar .tree-tool-button {
        display: inline-flex;
        margin-right: 8px;
        padding: 4px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        color: var(--icon-color);
    }

    .tree-toolbar .tree-tool-button i {
        font-size: 20px;
        cursor: pointer;
		transition: color .4s ease, text-shadow .4s ease;
    }

    .tree-toolbar .tree-tool-button i:hover {
        color: var(--icon-color-hover);
        text-shadow: 0px 0px 15px var(--icon-color-hover-shadow);
    }

    .treeitem-icon {
        background-image: url('./icons/icon_unknown.png');
        width: 24px;
        height: 24px;
        background-position: 0 0;
        vertical-align: middle;
    }

    .treeview-footer
    {
        position: absolute;
        width: 100%;
        height: 32px;
        margin: 0px;
        left: 0px;
        bottom: 0px;
        border-top: 2px var(--border-color) solid;
    }

    .projects-tree .projects-menu
    {
        position: absolute;
        background-color: var(--context-menu-background-color);
        border: 1px solid var(--border-color);
        left: 14px;
        top: 14px;
        width: 200px;
        padding: 8px;
        font-size: 16px;
        display: none;
        z-index: 999;           /* above everything ? */
    }
    
    .projects-tree .projects-menu .p-menuitem
    {
        padding: 4px;
        line-height: 14px;
        font-size: 14px;
        cursor: pointer;
    }

    .projects-tree .projects-menu .p-menuitem a
    {
        color: var(--color);
        font-weight: normal;
        height:24px;
    }

    .projects-tree .projects-menu .p-menuitem a:hover
    {
        color: var(--color-hover);
        text-shadow: 0 0 15px var(--icon-color-hover-shadow);
    }

    .projects-tree .projects-menu .p-menuitem .p-menuitem-icon
    {
        margin-right: 8px;
    }

    .fa-caret-right, .fa-caret-down {
        font-size: 23px;
        vertical-align: middle;
        padding-right: 5px;
    }
}

.icon_aoz, .icon_aozinc, .icon_aoz_file, .icon_aoz_music, .icon_aoz_zip, .icon_amos, .icon_aoz3d, .icon_js, .icon_unknown, .icon_audio, .icon_css, .icon_html, .icon_image, .icon_json, .icon_pdf, .icon_excel, .icon_text, .icon_video, .icon_xml {
    margin-left: 22px;
}

.icon_project {
    background-image: url('./icons/icon_project.png');
}

.icon_folder {
    background-image: url('./icons/icon_folder.png');
}

.icon_folder_resources {
    background-image: url('./icons/icon_resources.png');
}

.icon_folder_assets {
    background-image: url('./icons/icon_assets.png');
}

.icon_folder_images {
    background-image: url('./icons/icon_images.png');
}

.icon_folder_icons {
    background-image: url('./icons/icon_icons.png');
}

.icon_folder_samples {
    background-image: url('./icons/icon_audios.png');
}

.icon_folder_musics {
    background-image: url('./icons/icon_musics.png');
}

.icon_folder_fs {
    background-image: url('./icons/icon_fs.png');
}

.icon_amos {
    background-image: url('./icons/icon_amos.png');
}

.icon_aoz {
    background-image: url('./icons/icon_aoz.png');
}

.icon_aozinc {
    background-image: url('./icons/icon_aozinc.png');
}

.icon_aoz_file {
    background-image: url('./icons/icon_aoz_file.gif');
}

.icon_aozip {
    background-image: url('./icons/icon_aozip.png');
}

.icon_audio {
    background-image: url('./icons/icon_audio.png');
}

.icon_css {
    background-image: url('./icons/icon_css.png');
}

.icon_excel {
    background-image: url('./icons/icon_excel.png');
}

.icon_html {
    background-image: url('./icons/icon_html.png');
}

.icon_image {
    background-image: url('./icons/icon_image.png');
}

.icon_json {
    background-image: url('./icons/icon_json.png');
}

.icon_pdf {
    background-image: url('./icons/icon_pdf.png');
}

.icon_text {
    background-image: url('./icons/icon_txt.png');
}

.icon_video {
    background-image: url('./icons/icon_video.png');
}

.icon_xml {
    background-image: url('./icons/icon_xml.png');
}

.icon_music {
    background-image: url('./icons/icon_music.png');
}

.icon_js {
    background-image: url('./icons/icon_js.png');
}

.icon_aoz3d {
    background-image: url('./icons/icon_aoz3d.png');
}

.icon_unknown {
    background-image: url('./icons/icon_unknown.png');
}
