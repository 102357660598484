* {
    box-sizing: border-box;
}

.unavailable {
    filter: brightness(0.5);
}

.no-text-select {
    user-select: none;
    -webkit-user-drag: none;  /* not official */
}

.hidden {
    display: none!important;
}

:root {
    --aoz-orange: #ffac00;
}
