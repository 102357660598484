:root{
    --font-name: "Open Sans", sans-serif;
    --font-size: 1rem;
    --background-color: #333333;
    --background-color-hover: #346170;    
    --border-color: #888888;
    --color: #C0C0C0;
    --color-hover:#FFFFFF;
    --color-disabled: #888888;
    --color-option-disabled: #505050;
    --title-color: #FFFFFF;
    --scrollbar-track-color:#444444;
    --scrollbar-thumb-color:#e7a522;
    --link-color: #177c9e;
    --link-color-hover: #e7a522;
    --button-color: #346170;
    --button-color-hover: #177c9e;
    --button-color-disabled: #A0A0A0;
    --button-label-color: #E0E0E0;
    --button-label-color-hover: #FFFFFF;
    --button-label-color-disabled: #888888;
    --button-special-color:#d37f17;
    --button-special-color-hover:#57b4bb;
    --button-special-label-color: #eff5b6;
    --button-special-label-color-hover: #f5e611;
    --messenger-color: #346170;
    --messenger-info-color: #177c9e;
    --messenger-error-color: #ff0000;
    --messenger-warn-color: #e7a522;
    --visitor-message-color: #f56c35;
    --icon-color: #f0f0f0;
	--icon-color: var(--aoz-orange);
	--icon-color-hover: gold;
    --icon-color-hover-shadow: #00f0f0;
    --icon-color-disabled: #888888;
    --background-color-table-header: #346170;
    --background-color-table-row: #e4e4e4;
    --background-color-table-topic: #acacac;
    --color-table-row: #000000;
    --handler-color: #346170;
    --handler-color-hover: #177c9e;
    --splitter-gutter-color: #444444;
    --input-background-color: #f0f0f0;
    --input-border-color: #888888;
    --input-border-color-focus: #177c9e;
    --input-font-color: #444444;
    --input-icon-color: #444444;
    --input-placeholder-color: #888888;
    --treenode-label-color: #f0f0f0;
    --treenode-label-color-hover: #00f0f0;
    --project-icon-color: #e72295;
    --folder-icon-color: #eeee00;
    --word-icon-color: #00aaee;
    --powerpoint-icon-color: #d6531f;
    --excel-icon-color: #00a000;
    --pdf-icon-color: #ff0000;
    --text-icon-color: #f0f0f0;
    --audio-icon-color: #ff7aad;
    --video-icon-color: #eaabfa;
    --image-icon-color: #74d2f7;
    --css-icon-color: #faf5ad;
    --html-icon-color: #5d77ec;
    --javascript-icon-color: #6be26b;
    --json-icon-color: #e75e0f;
    --zip-icon-color: #ffff00;
    --aoz-icon-color: #e7a52;
    --treeview-selected-background-color: #177c9e;
    --treeview-selected-color: #f0f0f0;
    --tab-background-color:#264650;
    --tab-selected-background-color:#346170;
    --tab-selected-border-color:#00aaee;
    --tab-color:#c0c0c0;
    --tab-selected-color: #e7a522;
    --tab-close-color: #C0C0C0;
    --tab-close-color-hover: #FFFFFF;
    --shadow-color-hover: #c6c1f3;
    --error-message-color: #ec5f5f;
    --error-message-color-hover: #f3c6c6;
    --warn-message-color: #e7a522;
    --warn-message-color-hover: #f7f7f7;
    --background-color-qrcode-url: #177c9e;
    --confirm-icon-color: #42ca64;
    --confirm-message-color:#ffffff;
    --form-label-color: #e7a522;
    --context-menu-background-color: #282828;
}