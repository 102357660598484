@media screen /*and (min-width : 1024px) */
{
    .code-editor-container {
        position: absolute;
        left: 308px;
        top: 40px;
        right: 350px;
        bottom: 16px;
        margin: 0;
        padding: 0;
        height: calc(100% - 99px);
        /**
        position: relative;
        height: calc(100% - 5px);
        */
    }
    
    .code-editor {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .monaco-editor {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    div.monaco-editor div.view-overlays div.current-line {  /* specificity 0,3,3 */
        border: none; 
    }

    .errorMargin {
        background: red;
    }
    
    .errorLine {
        /* Make sure to use transparent colors for the selection to work */
        /*background: rgba(190, 21, 21, 0.5);*/
        text-decoration-style: wavy;
        text-decoration-line: underline;
        text-decoration-color: rgba(190, 21, 21);
        text-decoration-skip-ink: none;
    }

    .call-aoz, .call-aoz-error {
        width: 20px !important;
        height: 20px !important;
        margin: 4px 0 0 4px;
    }

    .call-aoz-error {
        background-color: rgba(255,0,0,.5);
        background-blend-mode: multiply;
        border-radius: 20px;        /* FVL: haha ! gotcha ! */
    }

    .margin-view-overlays { /* specific to monaco editor, this is the zone with the line numbers */
        user-select: none;
    }
}
