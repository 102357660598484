@media screen /*and (min-width : 1024px) */
{
    .smartphone, .tablet 
    {
        display: none;
    }
    
    .mask
    {
        position: fixed;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0,0,0,0.5);
        z-index: 1000;
    }

    .account-panel-footer{
        position: fixed;
        width: 100%;
        bottom: 10px;
        text-align: center;
        font-size: 1.5vw;
    }

    .account-panel
    {
        position: relative;
        width: 800px;
        background-color: var(--background-color);
        border: 1px var(--border-color) solid;
        padding: 8px;
        box-shadow: 0 0 10px 0 var(--border-color);
    }
    
    .account-panel .p-dialog-header {
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-bottom: 1px solid var(--border-color);
    }

    .account-panel .p-dialog-header .p-dialog-title
    {
        font-size:20px;
        color: var(--color-hover);
        font-weight: bold;
        text-shadow: 0px 0px 5px var(--shadow-color-hover);
    }
    
    .account-form
    {
        display: block;
        padding: 4px;
        width: 100%;
    }

    .account-form table
    {
        width: 100%;
        height: 100%;
    }

    .account-form td
    {
        height: 40px;
    }

    .account-form .td-left
    {
        display: inline-block;
        width: 35%;
        height: 32px;
        font-size: 16px;
        font-weight: bold;
        color: var(--form-label-color);
        padding-top: 4px;
    }

    .account-form .td-right
    {
        display: inline-block;
        width: 65%;
        height: 32px;
        font-size: 16px;
        text-align: right;
        color: var(--color);
        padding-top: 4px;
    }

    .account-form input
    {
        font-size: 16px;
        text-align:right;
        padding-right: 10px;
        color: var(--input-font-color);
        background-color: var(--input-background-color);
        border: 1px solid var(--input-border-color);
    }

    .account-form input[type="checkbox"]
    {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    
    .account-form input[type="text"]
    {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        padding-left: 8px;
    }

    .account-buttons {
        display: flex;
        align-items: end;

        a {
            margin: 4px 4px 4px 32px;
        }

        .cancel {
            margin-left: auto;
        }
    }

    .account-buttons button
    {
        position: relative;
        display: inline-block;
        height: 40px;
        margin: 4px;
        font-size: 16px;
        top:0px;
    }
    
    .account-buttons .save
    {
        background-color: var(--button-special-color);
        color: var(--button-special-label-color);
        /*width: 240px;
        margin-left: 128px;*/
    }

    .account-buttons .save:hover
    {
        background-color: var(--button-special-color-hover);
        color: var(--button-special-label-color-hover);
    }

}
