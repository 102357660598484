@media screen /*and (min-width : 1024px) */
{
    .p-dialog-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color:rgba(0,0,0,0.5);
    }

    .new-item-panel.p-dialog {
        border: 1px solid var(--border-color);
        background-color: var(--background-color);
        width: 400px;
        height: auto;
        box-shadow: 0 0 10px 0 var(--border-color);
    }

    .new-item-panel .p-dialog-header {
        padding: 8px;
        background-color: var(--header-color);
        color: var(--header-text-color);
        font-size: 16px;
        font-weight: bold;
    }
    
    .new-item-panel .p-dialog-header .p-dialog-title
    {
        font-size:20px;
        color: var(--color-hover);
        font-weight: bold;
        text-shadow: 0px 0px 5px var(--shadow-color-hover);
    }

    .new-item-panel .new-item-form {
        padding: 8px;
    }

    .new-item-panel table {
        width: 100%;
    }

    .new-item-panel td {
        height: 40px;
    }

    .new-item-panel .td-left
    {
        display: inline-block;
        width: 25%;
        height: 32px;
        font-size: 16px;
        font-weight: bold;
        color: var(--form-label-color);
        padding-top: 0px;
    }
    
    .new-item-panel .td-right
    {
        display: inline-block;
        width: 75%;
        font-size: 18px;
        text-align: right;
        color:var(--color);
        margin-bottom: 8px;
    }
    
    .new-item-panel input, .new-item-panel .p-dropdown
    {
        font-size: 14px;
        color: var(--input-font-color);
        background-color: var(--input-background-color);
        border: 1px solid var(--input-border-color);
    }

    .new-item-panel input[type="text"], .new-item-panel .p-dropdown
    {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        padding-left: 8px;
    }

    .new-item-panel .p-dropdown
    {
        text-align: right;
        padding-right: 24px;
    }

    .p-dropdown-label
    {
        font-size: 14px;
        color: var(--input-text-color);
        margin-right: 16px;
        padding-top: 9px;
    }

    .p-dropdown-items-wrapper
    {
        text-align: left;
        padding-right: 24px;
        color: var(--background-color);
        border: 1px solid var(--border-color);
        background-color: var(--color);
        padding: 8px;
    }

    .p-dropdown-item:hover
    {
        background-color: var(--background-color-hover);
        color: var(--color-hover);
    }

    .new-item-buttons {
        padding: 8px;
        width: 100%;
        background-color: var(--footer-color);
        display: flex;
        justify-content: flex-end;
    }

}
