.treeview {
    margin: 0;
    padding: 0;
    list-style: none;
}

.treeview .treeview-header {
    width: 100%;
    border-bottom: 1px solid #888888;
}

.treeview-content-scroller
{
    position:absolute;
    top: 80px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: auto;
}

.treeview-content
{
    list-style: none;
    border: 1px solid #888888;
    padding: 8px;
    overflow: none;
}

.treeview ul {
    list-style: none;
    margin: 0px;
    padding-left: 16px;
    width: max-content;
}

.treeview li {
    margin: 0;
    padding: 0;
    list-style: none;
    cursor: pointer;
}

.treeview i {
    margin-right: 5px;
}

.treeview li span {
    cursor: pointer;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

.treeview li span.selected {
    background-color: #e0e0ff;
}

.tree-filters {
    margin: 10px;
    height: 40px;
}

input[type="text"].tree-filters-input {
    margin: 10px;
    height: 32px;
    border-radius: 5px;
    padding-left: 5px;
    border: 1px #888888 solid;
    padding-right: 40px;
    width: 100%
}

input[type="text"].tree-filters-input:focus {
    outline-offset: none;
    outline: none;
    border: 4px #0088aa double;
}

input.tree-filters-input::placeholder {
    color: #888888;
    font-style: italic;
}

.tree-filters-icon {
    margin-left: -40px;
    color: #888888;
    cursor: pointer;
}

.tree-toolbar
{
    display:flex;
    width: 100%;
    height: 24px;
    margin: 4px;
}

.tree-toolbar .tree-tool-button {
    display: inline-flex;
    margin-right: 8px;
    padding: 4px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.tree-tool-separator {
    margin-right: 8px;
    margin-left: 8px;
    border-right: 1px solid #888888;
} 

/*.tree-toolbar .tree-tool-button i {
    font-size: 20px;
    cursor: pointer;
}*/

.tree-toolbar .tree-tool-button i:hover {
    /*color: #0088aa;*/
    cursor: pointer;
}

.treeitem-icon {
    display: inline-flex;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: contain;
}

.treeview-footer
{
    width: 100%;
    height: 32px;
    margin: 4px;
    border-top: 1px solid #888888;
}