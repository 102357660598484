@media screen /*and (min-width : 1024px) */
{
    .statusbar {
        position: fixed;
        display: block;
        left: 0px;
        bottom:0px;
        right: 0px;
        height: 24px;
        background-color: var(--background-color);
        border-top: 1px var(--border-color) solid;
        z-index: 500;
        padding: 2px;
    }

    .statusbar tbody {
        position: relative;
        display:block;
        width: 100%;
    }

    .statusbar tr {
        display: flex;
        width: 100%;
    }

    .statusbar td {
        width: 33.33%;
        /*padding-top: 4px;*/
    }

    .statusbar td.status-left {
        text-align: left;
        padding-left: 5px;
    }

    .statusbar td.status-center {
        text-align: center;
    }

    .statusbar td.status-right {
        text-align: right;
        padding-right: 5px;
    }
    
    .visitor-message
    {
        position: absolute;
        display: inline-block;
        z-index: 500;
        bottom: 3px;
        right: 25%;
        left: 25%;
        width: 50%;
        height: 19px;
        font-size: var(--font-size);
        font-weight: bold;
        color: var(--visitor-message-color);
        cursor: pointer;
        text-align: center;
    }
    
    .blink_me {
        animation: blinker 1s linear infinite;
    }
    
    @keyframes blinker {
        50% {
            color: var(--background-color);
        }
    }
    
    .statusbar .messages, .statusbar .position
    {
        display: inline-block;
        width: auto;
    }

    .statusbar .message-info {
        display:inline-block;
        background-repeat: no-repeat;
        background-size: contain;
        width: 18px;
        text-align: left;
        padding-left: 26px;
        cursor: pointer;
    }

    .statusbar .message-info.errors
    {
        background-image: url('../../../../styles/images/error_status_icon.png');
    }

    .statusbar .message-info.warnings
    {
        background-image: url('../../../../styles/images/warn_status_icon.png');
    }
}
