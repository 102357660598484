@media screen /*and (min-width : 1024px) */
{
    .toolbar {
        position: fixed;
        /*display: block;*/
        left: 0px;
        top:0px;
        right: 0px;
        height: 40px;
        background-color: var(--background-color);
        /** border-bottom: 1px var(--border-color) solid; */
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .toolbar tbody {
        position: relative;
        display:block;
        width: 100%;
        height: 40px;
    }

    .toolbar tr {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .toolbar td {
        width: 33.33%;
    }

    .toolbar .left {
        text-align: left;
        padding: 5px;
        display: flex;
        align-items: center;
    }

    .toolbar .center {
        text-align: center;
    }

    .toolbar .right {
        text-align: right;
        padding: 5px;
    }

    .toolitem {
        display: inline-block;
        /*width: 32px;*/
        height: 32px;
        padding: 4px;
        border: none;
        border-radius: 0px;
        cursor: pointer;
        margin: 0 4px 0 4px;
    }

    .toolitem[disabled] {
        opacity: 0.3;
        cursor: not-allowed;
        pointer-events: none;
    }

    .toolitem i {
        font-size: 24px;
        color: var(--icon-color);
		transition: color .4s ease, text-shadow .4s ease;
    }

    .toolitem i:hover {
		color: var(--icon-color-hover);
        text-shadow: 0px 0px 15px var(--icon-color-hover-shadow);
    }

    .aoz-icon {
        /*display: inline-flex;*/
        width: 24px;
        /*min-width: 24px;*/
        height: 24px;
        /*border: none;
        border-radius: 0px;*/
        /*background-color: var(--background-color);*/
        margin: 4px;
        /*cursor: pointer;*/
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url( './favicon.png');
    }
    
    .aoz-title {
        /*display: inline-flex;*/
        font-size: 18px;
        color: var(--title-color);
        margin: 4px;
        /*vertical-align: middle;*/
        font-weight: bold;
        /*height: 32px;
        line-height: 10px;*/
        text-wrap: nowrap;
    }

    .aoz-repo {
        text-wrap: nowrap;
        margin-left: 8px;
        color: yellow;
        font-size: 1.5em;
        font-weight: bold;
    }

    .toolbar td.left .toolitem 
    {
        /*display: inline-block;
        position: absolute;*/
        height: initial;
    }

    .toolbar .main-commands {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        display: block;
        /** background-color: var(--background-color);*/ 
        height: 32px;
        width: 128px;
        border: none;
        margin-top: 4px;
        /**
        border: 3px solid var(--border-color);
        border-top: none;
        border-radius: 0px 0px 8px 8px;
        */
    }

    .toolbar .main-commands .toolitem i {
        font-size: 24px;
    }
}
