@media screen /*and (min-width : 1024px) */
{
    .devices {
        position: absolute;
        display: inline-block;
        font-size: 24px;
        font-weight: normal;
        margin-bottom: 0px;
    }

    .devices span {
        display: inline-block;
        margin-left: 8px;
    }

    .devices span.fa-display, .devices span.fa-mobile-screen, .devices span.fa-tablet-screen-button {
        color: var(--text-color);
    }

    .devices span.fa-mobile-screen, .devices span.fa-tablet-screen-button {
        opacity: 0.33
    }

}
