@media screen /*and (min-width : 1024px) */
{
   .aidocpanel  {
        position: absolute;
        right: 0px;
        width: 350px;
        top: 39px;
        bottom: 32px;
        height: calc(100% - 64px);
        background-color: var(--background-color);
        border: none;
        border-left: 1px var(--border-color) solid;
        user-select: none;

        /**
        position: relative;
        width: 100%;
        height: calc(100% - 10px);
        border-left: 1px solid var(--border-color);
        */
    }

    .aidocpanel-tabs {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 32px;
    }

    .aidocpanel-tabs table {
        width: 100%;
        height: 100%;
        border-collapse: collapse;
    }

    .aidocpanel-tabs table tr {
        height: 100%;
    }

    .aidocpanel-tabs table tr td {
        width: 50%;
        height: 100%;
        text-align: center;
        cursor: pointer;
        background-color: var(--background-color);
        color: var(--text-color);
        border: var(--border-color) 1px solid;
        padding:0;
        margin:0;
        cursor: pointer;
    }

    .aidocpanel-tabs table tr td.active {
        background-color: var(--background-color-hover );
        color: var(--color-hover );
        font-weight: bold;
    }

    .aidocpanel-content {
        width: 100%;
        height:calc(100% - 32px);
        border: none;
    }

    .aidocpanel-iframe {
        width: 100%;
        height: 100%;
        border-collapse: collapse;
        border: none;
    }

    .aidocpanel .splitter
    {
        top: calc(50% - 40px);
        left: -3px;
        cursor: ew-resize;
    }

    .aidocpanel .save {
        background-color: var(--tab-background-color);
        border-radius: 5px 5px 5px 5px;
        position: absolute;
        left: -60px;
        top: 40px;
        opacity: 0.25;
        font-size: 24px;
        width: 36px;
        height: 36px;
        color: var(--aoz-orange);
        display: grid;
        place-content: center;
        transition: opacity 1s ease;
        cursor: pointer;
    }

    .aidocpanel .save.full-visible, .aidocpanel .save:hover {
        opacity: 1;
    }

}
