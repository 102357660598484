@media screen /*and (min-width : 1024px) */
{    
    html, body {
        background-color: var(--background-color);
        color: var(--color);
        margin: 0px;
        padding: 0px;
        font-family: var(--font-name);
        font-size: 14px; /*1.0vw;*/
        overflow: hidden;
    }
    
    button.p-dialog-header-close {
        width: 32px;
        border: 1px solid var(--border-color);
        background: transparent;
        font-size: 32px;
        line-height: 8px;
        font-weight: bold;
        padding: 0;
    }
    
    /** SCROLLBAR */
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    
    ::-webkit-scrollbar-track {
        background: var(--scrollbar-track-color);
    }
    
    ::-webkit-scrollbar-thumb {
        background: var(--scrollbar-thumb-color);
    } 
    
    li[role="separator"] {
        border: 1px solid var(--border-color);
    }
}