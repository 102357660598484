@media screen /*and (min-width : 1024px) */
{
    .messages-panel {
        position: fixed;
        display: block;
        left: 0px;
        right: 0px;
        bottom: 32px;
        height: 200px;
        background-color: var(--background-color);
        z-index: 500;
        padding: 2px;
        border-top: 1px solid var(--border-color); 
    }

    .messages-panel-title {
        position: relative;
        display: block;
        width: auto;
        height: 28px;
        background-color: var(--background-color);
        border-bottom: 1px var(--border-color) solid;
        font-weight: bold;
        padding-top: 8px;
        padding-left: 10px;
    }

    .messages-panel-title span {
        position: absolute;
        display: block;
        top: 8px;
        right: 10px;
        font-size: 22px;
        cursor: pointer;
        font-weight: bold;
    }

    .messages-panel-content {
        position: relative;
        display: block;
        width: calc(100% - 20px);
        height: 144px;
        overflow-y: auto;
        overflow-x: auto;
        background-color: #000000;
        padding: 8px;
    }

    .messages-panel-content .message {
        font-family: 'Courier New', Courier, monospace;
        font-size: 16px;
        cursor: pointer;
        margin-bottom: 3px;
    }

    .messages-panel-content .message.error {
        color: var(--error-message-color);
        background-image: url('../../../../styles/images/error_status_icon.png');
        background-repeat: no-repeat;
        background-size: 1em;
        padding-left: 26px;
    }

    .messages-panel-content .message.error:hover {
        color: var(--error-message-color-hover);
    }

    .messages-panel-content .message.warning {
        color: var(--warn-message-color);
        background-image: url('../../../../styles/images/warn_status_icon.png');
        background-repeat: no-repeat;
        background-size: 1em;
        padding-left: 26px;
    }

    .messages-panel-content .message.warning:hover {
        color: var(--warn-message-color-hover);
    }
}   