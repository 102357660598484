.messenger
{
    position: absolute;
    display: block;
    width: 100%;
    height: 32px;
    left: 0px;
    top: 0px;
    z-index: 5000;
    cursor: pointer;
}

.messenger-popup
{
    position: relative;
    display: block;
    left: 0px;
    top: 0px;
    width: 100%;
    height:100%;
    background-color:var(--messenger-color);
    border: none;
}

.messenger-info
{
    background-color: var(--messenger-info-color);
}

.messenger-error
{
    background-color: var(--messenger-error-color);
}

.messenger-warn
{
    background-color: var(--messenger-warn-color);
}

.messenger-popup span
{
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 100%;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
}

.messenger-popup .messenger-icon
{
    position: absolute;
    display: block;
    color: white;
    width: 32px;
    height: 32px;
    top: 0px;
    left: 5px;
    font-size:20px;
}

.messenger-popup .popup-close-button
{
    color: white;
    margin-right: 10px;
}