@media screen /*and (min-width : 1024px) */
{
    .smartphone, .tablet 
    {
        display: none;
    }

    .aoz-logo
    {
        position: relative;
        background-image: url('./images/logo-aoz-2.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0 0;
        width: 20%;
        height: 25%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 32px;
    }

    .auth-panel-footer{
        position: fixed;
        width: 100%;
        bottom: 10px;
        text-align: center;
        font-size: 1.5vw;
    }

    .auth-panel
    {
        position: relative;
        background-color: var(--background-color);
        width: 540px;
        border: 1px var(--border-color) solid;
        padding: 8px;
    }
    
    .auth-panel .p-dialog-header {
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-bottom: 1px solid var(--border-color);
    }

    .auth-panel .p-dialog-header .p-dialog-title
    {
        font-size:20px;
        color: var(--color-hover);
        font-weight: bold;
        text-shadow: 0px 0px 5px var(--shadow-color-hover);
    }
    .auth-panel .auth-email-field, .auth-panel .auth-password-field, .auth-panel .auth-remember-field
    {
        position: relative;
        display: block;
        height: 40px;
        margin: 4px;
    }

    .auth-panel label
    {
        display: inline-block;
        width: 25%;
        font-size:18px;
    }

    .auth-panel input[type="email"], .auth-panel input[type="password"]
    {
        position: relative;
        width: 75%;
        font-size: 18px;
        height: 100%;
        padding: 1vh;
    }

    .auth-remember-field input[type="checkbox"]
    {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 130px;
        margin-right: 4px;
        margin-top: 8px;
    }

    .auth-remember-field label
    { 
        position: absolute;
        display: inline-block;
        font-size: 18px;
        width: auto;
        line-height:35px;
    }

    .auth-buttons {
        display: flex;
        justify-content: end;
    }

    .auth-buttons .btn-create, .auth-buttons #auth-btn-click
    {
        position: relative;
        display: inline-block;
        height: 40px;
        margin: 4px;
        font-size: 18px;
        top:0px;
    }

    .auth-buttons .btn-create
    {
        width: 200px;
    }
    .auth-buttons #auth-btn-click
    {
        width: 128px;
    }
    .auth-buttons .btn-special
    {
        background-color:var(--button-special-color);
        color:var(--button-special-label-color);
        font-weight: bold;
    }

    .auth-buttons .btn-special:hover
    {
        background-color:var(--button-special-color-hover);
        color:var(--button-special-label-color-hover);
        font-weight: bold;
    }    
}
