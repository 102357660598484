@media screen /*and (min-width : 1024px) */
{
    .p-dialog-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color:rgba(0,0,0,0.5);
    }

    .aoz-confirm.p-dialog {
        border: 1px solid var(--border-color);
        background-color: var(--background-color);
        width: 400px;
        height: 200px;
        box-shadow: 0 0 10px 0 var(--border-color);
    }

    .aoz-confirm .p-dialog-header {
        padding: 8px;
        background-color: var(--header-color);
        color: var(--header-text-color);
        font-size: 16px;
        font-weight: bold;
    }

    .aoz-confirm .p-dialog-content {
        padding: 8px;
    }

    .aoz-confirm .p-confirm-dialog-icon {
        font-size: 36px;
        color: var(--confirm-icon-color);
        padding-bottom: 40px;
        margin-left: 16px;
    }
    
    .aoz-confirm .p-confirm-dialog-message {
        font-size: 14px;
        margin-right: 8px;
        margin-left: 24px;
        color: var(--confirm-message-color);
        text-align: center;
    }

    .aoz-confirm .p-dialog-footer {
        padding: 8px;
        background-color: var(--footer-color);
        display: flex;
        justify-content: flex-end;
    }
}
