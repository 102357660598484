.mask
{
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
}

@media screen /*and (min-width : 1024px) */
{
    html, body {
        background-color: var(--background-color);
        color: var(--color);
        margin: 0px;
        padding: 0px;
        font-family: var(--font-name);
        font-size: 1.0vw;
        overflow: hidden;
    }

    .application {
        height: 100vh;
    }

    .hidden {
        display: none;
    }

    .topbar {
        height: 40px;
    }

    minimap-shadow-visible
    {
        display: none;
    }

    /**
    #topbar, #main-toolbar, #status-bar, #code, #project-pane, #doc-pane
    {
        display: none;
    }
    */

    #status-bar-position {
        position:relative;
        display:inline-block;
        width:50%;
    }

    #status-bar-copyright {
        position:relative;
        display:inline-block;
        width:50%;
        text-align:right;
    }

    a {
        color: var(--link-color);
        font-weight: bold;
    }

    a:hover {
        color: var(--link-color-hover);
    }


    button
    {
        background-color: var(--button-color);
        color: var(--button-label-color);
        border: none;
        padding: 5px;
        margin-left: 5px;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight:bold;
        width: 128px;
        height: 32px;
        cursor: pointer;
    }

    button:hover
    {
        background-color: var(--button-color-hover);
        color: var(--button-label-color-hover);
    }
}