@media screen /*and (min-width : 1024px) */
{
    .tabbar {
        position:relative;
        display:block;
        height: 33px;
        overflow: hidden;
        overflow-x:auto;
        width: auto;
        display: flex;
        align-items: center;
    }

    /* https://syntackle.com/blog/changes-to-scrollbar-styling-in-chrome-121/ */
    @supports not selector(::-webkit-scrollbar)  {
        .tabbar
        { /* FF & others */
            scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);;
            scrollbar-width: thin;
        }
    }

    @supports selector(::-webkit-scrollbar) {
        .tabbar::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
    }

    .content-tabbar {
        margin: 0;
        padding: 0;
        height:inherit;
        width:auto;
        border-collapse: collapse;
        border-spacing: 0;
    }

    .content-tabbar tbody {
        margin: 0;
        padding: 0;
        border-collapse: collapse;
        border-spacing: 0;
    }
    
    .content-tabbar tr {
        display:flex;
        margin: 0;
        padding: 0;
        width: auto;
        border-collapse: collapse;
        border-spacing: 0;
    }

    .content-tabbar td {
        /*display:inline-block;*/
        margin: 0;
        width: max-content;     /* this should be fixed */
        min-width: 240px;
        border: 1px solid var(--tab-background-color);
        border-bottom: none;
        border-radius: 5px 5px 0 0;
        background-color: var(--tab-background-color);
        color: var(--tab-color);
        cursor: pointer;
        text-align: center;
        /*vertical-align: middle;*/
        margin-right: 3px;
        padding: 3px;

        display:inline-flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
    }

    .content-tabbar td:hover {
        background-color: var(--tab-selected-background-color);
        color: var(--tab-selected-color);
    }

    .content-tabbar td.selected {
        background-color: var(--tab-selected-background-color);
        color: var(--tab-selected-color);
        font-weight: bold;
        /*border-bottom: 2px solid var(--tab-selected-border-color);*/
        box-shadow: 0 -3px inset;   /* inset border ! */
        padding-top: 1px;           /* might look weird */
    }

    /*.tabicon, .tabtitle, .tabclose {
        display: inline-grid;
        vertical-align: middle;
    }*/

    .tabicon {
        background-size: contain;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        text-align: left;
        align-items: center;
        margin-left: 8px;
    }

    /*.tabtitle {
        width: fit-content;
        min-width: 160px;
        text-align: center;
    }*/

    .tabtitle .save-state {
        padding: 6px;
        transition: scale .5s ease;
    }

    .tabtitle .save-state:hover {
        scale: 1.5;
    }

    .tabclose {
        /*width: 24px;
        height: 24px;
        text-align: right;*/
        font-size: 20px;
        margin-right: 8px;
        color: var(--tab-close-color)
    }

    .tabclose:hover {
        color: var(--tab-close-color-hover)
    }
}
