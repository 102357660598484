@media screen /*and (min-width : 1024px) */
{
    .smartphone, .tablet 
    {
        display: none;
    }

    .project-panel-footer{
        position: absolute;
        right: 10px;
        bottom: 10px;
        text-align: right;
        font-size: 1.5vw;
    }

    .project-panel
    {
        position: relative;
        width: 800px;
        height: 500px;
        border: 1px var(--border-color) solid;
        padding: 8px;
        background-color: var(--background-color);
        box-shadow: 0 0 20px var(--shadow-color)
    }
    
    .project-panel .p-dialog-header {
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-bottom: 1px solid var(--border-color);
    }

    .project-panel .p-dialog-header .p-dialog-title
    {
        font-size:20px;
        color: var(--color-hover);
        font-weight: bold;
        text-shadow: 0px 0px 5px var(--shadow-color-hover);
    }

    .project-panel-topics
    {
        position: absolute;
        padding: 0px;
        width: 200px;
        top: 56px;
        bottom: 10px;
    }

    .project-panel-topics ul
    {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
    }

    .project-panel-topics ul li
    {
        padding: 5px;
        margin: 0px;
        color: var(--color);
        font-weight:bold;
        cursor: pointer;
    }
    
    .project-panel-topics ul li.selected
    {
        background-color:var(--background-color-hover);
        color: var(--color-hover);
    }

    .project-panel-topics ul li:hover
    {
        background-color:var(--background-color-hover);
        color: var(--color-hover);
    }

    .project-panel-content
    {
        position: absolute;
        left: 210px;
        top: 56px;
        right: 10px;
        bottom: 56px;
        display: block;
        padding: 10px;
        border: 1px solid var(--border-color);
    }

    .project-panel label
    {
        display: inline-block;
        width: 25%;
        font-size:14px;
        font-weight: bold;
        color: var(--color-hover);
    }

    .project-panel input{
        width: 70%;
        font-size:14px;
        padding: 5px;
        padding-left: 10px;
        margin: 5px;
        font-family: var(--font-family);
        color: var(--input-font-color);
        border-radius: 16px;
        border: none;
    }

    .project-panel input[type="checkbox"]{
        width: 18px;
        height: 18px;
    }

    .p-checkbox-box
    {
        display: none;
    }

    #project-fullscreen, #project-keep-proportions, #project-smoothing{
        width: 32px;
        vertical-align: middle;
        display:inline-flex;
    }

    .project-panel .p-button-group
    {
        width: auto;
        text-align: right;
        display: inline-flex;
        justify-content: space-between;
        padding-left: 10px;
    }

    .project-panel .p-button-group .p-button {
        margin: 0;
        border-radius: 16px;
        font-size: 14px;
        font-weight: bold;
        height: 32px;
        width: 96px;
        background-color: var(--button-color);
        padding: 5px 10px;
        border: none;
    }

    .project-panel .p-button-group .p-button:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }    

    .project-panel .p-button-group .p-button:not(:first-of-type):not(:last-of-type) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    } 
    
    .project-panel .p-button-group .p-button:last-of-type {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    } 

    .project-panel .p-button-group .p-button.p-highlight {
        background-color: var(--button-color-hover);
        color: var(--color-hover);
        border: none;
    }

    .project-panel .p-button-group .p-button:hover {
        background-color: var(--button-color-hover);
        color: var(--color-hover);
    }
    
    #project-format .p-button {
       height: 96px;
       background-repeat: no-repeat;
       background-size: 80%;
       background-position: center;
    }

    #project-format .p-button[aria-label="16:9"] span, #project-format .p-button[aria-label="4:3"] span, #project-format .p-button[aria-label="16:10"] span {
        display: none;
    }

    #project-format .p-button[aria-label="16:9"] {
       background-image: url( 'images/16-9.png' );
    }

    #project-format .p-button[aria-label="4:3"] {
       background-image: url( 'images/4-3.png' );
    }

    #project-format .p-button[aria-label="16:10"] {
        background-image: url( 'images/16-10.png' );
     }
 
     #project-width, #project-height {
        width: 140px;
     }
     
     #code-protect .p-button {
        height: 96px;
        width: 88px;
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: center;
     }

    .project-buttons .btn-create, .project-buttons #auth-btn-click
    {
        position: relative;
        display: inline-block;
        height: 40px;
        margin: 4px;
        font-size: 18px;
        top:0px;
    }

    .project-buttons .btn-create
    {
        width: 200px;
    }

    .project-buttons #auth-btn-click
    {
        width: 128px;
    }

    .project-buttons .btn-special
    {
        background-color:var(--button-special-color);
        color:var(--button-special-label-color);
        font-weight: bold;
    }

    .project-buttons .btn-special:hover
    {
        background-color:var(--button-special-color-hover);
        color:var(--button-special-label-color-hover);
        font-weight: bold;
    }    

    .version
    {
        position: absolute;
        bottom: 10px;
        font-size: smaller;
        color: dimgrey;
    }

}
