.loader-animation {
    display: block;
    justify-content: center;
}

@media screen /*and (min-width : 1024px) */
{
    .loader-animation .label {
        position: absolute;
        display: block;
        height: 24px;
        text-align: center;
        font-size: 1.25vw;
        color: var(--color);
        text-shadow: 0 0 20px cyan;
        font-weight: bold;
        left: 0px;
        right: 0px;
        top: 50%;
    }

    .loader-animation .spinner {
        position: absolute;
        width: 56px;
        height: 56px;
        left: calc(50% - 28px);
        top: calc(50% - 88px);
        border-radius: 50%;
        border: 9px solid #ffc247;
        animation: spinner-bulqg1 0.8s infinite linear alternate, spinner-oaa3wk 1.6s infinite linear;
     }
     
     @keyframes spinner-bulqg1 {
        0% {
           clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
        }
     
        12.5% {
           clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
        }
     
        25% {
           clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
        }
     
        50% {
           clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
        }
     
        62.5% {
           clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
        }
     
        75% {
           clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
        }
     
        100% {
           clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
        }
     }
     
     @keyframes spinner-oaa3wk {
        0% {
           transform: scaleY(1) rotate(0deg);
        }
     
        49.99% {
           transform: scaleY(1) rotate(135deg);
        }
     
        50% {
           transform: scaleY(-1) rotate(0deg);
        }
     
        100% {
           transform: scaleY(-1) rotate(-135deg);
        }
     }

    .loader-animation .cube {
        position: absolute;
        width: 70.4px;
        height: 70.4px;
        left: calc(50% - 35.1px);
        top: calc(50% - 115.2px);
        animation: cube-y0fdc1 2.8s infinite ease;
        transform-style: preserve-3d;
    }
 
    .loader-animation .cube > div {
        background-color: rgba(255,231,71,0.2);
        height: 100%;
        position: absolute;
        width: 100%;
        border: 3.5px solid #ffe747;
    }

    .loader-animation .cube div:nth-of-type(1) {
        transform: translateZ(-35.2px) rotateY(180deg);
    }

    .loader-animation .cube div:nth-of-type(2) {
        transform: rotateY(-270deg) translateX(50%);
        transform-origin: top right;
    }

    .loader-animation .cube div:nth-of-type(3) {
        transform: rotateY(270deg) translateX(-50%);
        transform-origin: center left;
    }

    .loader-animation .cube div:nth-of-type(4) {
        transform: rotateX(90deg) translateY(-50%);
        transform-origin: top center;
    }

    .loader-animation .loader-animation .cube div:nth-of-type(5) {
        transform: rotateX(-90deg) translateY(50%);
        transform-origin: bottom center;
    }

    .loader-animation .cube div:nth-of-type(6) {
        transform: translateZ(35.2px);
    }

    @keyframes cube-y0fdc1 {
        0% {
            transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
        }

        50% {
            transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
        }

        100% {
            transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
        }
    }

    .loader-animation .build {
        position: absolute;
        width: 70.4px;
        height: 70.4px;
        left: calc(50% - 35.1px);
        top: calc(50% - 115.2px);
    }
    
    .loader-animation .cancel-button 
    {
        position: absolute;
        left: calc(50% - 64px);
        top: calc(50% + 64px);
    }

    .loader-animation .potion {
        position: absolute;
        width: 140px;
        height: 140.4px;
        left: calc(50% - 70.2px);
        top: calc(50% - 155.2px);
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(./images/potion.gif);
    } 
    
    .loader-animation .qrcode {
        position: absolute;
        width: 140px;
        height: 140.4px;
        left: calc(50% - 70.2px);
        top: calc(50% - 155.2px);
        background-repeat: no-repeat;
        background-size: contain;
    }

    .loader-animation .qrcode {
        position: absolute;
        width: 200px;
        height: 200px;
        left: calc(50% - 100px);
        top: calc(50% - 155px);
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer; 
    }

    .loader-animation .label.qrcode-info {
        font-size: 2vw;
        color: var(--color-hover);
        text-shadow: 0 0 20px cyan;
        top: calc(50% - 260px);
        margin-left: 100px;
        margin-right: 100px;
    }

    .loader-animation .label.qrcode-url {
        font-size: 2vw;
        top: calc(50% + 90px);
        text-shadow:none;
        background-color: var(--background-color-qrcode-url);
        height: auto;
        cursor: pointer;
        margin-left: 160px;
        margin-right: 160px;
        border-radius: 32px;
    }
    
    .loader-animation .label.qrcode-url:hover {
        font-size: 2vw;
        top: calc(50% + 90px);
        text-shadow: 0 0 20px cyan;
        color: var(--color-hover);
        background-color: var(--background-color-qrcode-url);
        height: auto;
        cursor: pointer;
        margin-left: 160px;
        margin-right: 160px;
        border-radius: 32px;
    }

    .loader-animation .flipping {
        position: absolute;
        width: 70.4px;
        height: 70.4px;
        left: calc(50% - 35.1px);
        top: calc(50% - 115.2px);
        animation: flipping-fmo34qmd 1.5s infinite;
        background-color: #ffe747;
     }
     
     @keyframes flipping-fmo34qmd {
        0% {
           transform: perspective(44.8px) rotate(45deg) rotateX(0deg) rotateY(0deg);
        }
     
        50% {
           transform: perspective(44.8px) rotate(45deg) rotateX(-180deg) rotateY(0deg);
        }
     
        100% {
           transform: perspective(44.8px) rotate(45deg) rotateX(-180deg) rotateY(-180deg);
        }
     }    
} 